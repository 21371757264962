export const desktopGutter = 20;
export const colorWhite = '#fff';
export const colorGrey10 = '#f1f1f1';
export const colorGrey20 = '#e9e9e9';
export const colorGrey30 = '#e4e4e4';
export const colorGrey40 = '#c4c4c4';
export const colorGrey60 = '#6f7070';
export const colorGrey70 = '#494a4a';
export const colorGrey80 = '#343434';
export const colorBlack80 = '#262626';
export const colorBlack90 = '#141414';
export const colorBlack = '#010101';
export const colorRed = '#c36161';
export const colorViolet = '#6f61c3';
export const colorLightViolet = '#8d7bf9';
export const colorAccent = colorViolet;
export const colorAccentDark = colorLightViolet;
export const colorTextMain = colorBlack80;
export const colorTextMainDark = colorGrey10;
export const colorTextAlt = colorGrey60;
export const colorDarkTextAlt = '#828282';
export const colorTextAltDark = 'rgb(241, 241, 241, 0.5)';
export const colorSmallButtonsBg = colorGrey30;
export const colorSmallButtonsText = colorBlack;
export const colorSmallButtonsHover = 'rgba(196, 196, 196, 0.7)';
export const colorSmallButtonsBgDark = 'transparent';
export const colorSmallButtonsTextDark = colorTextMainDark;
export const colorSmallButtonsHoverDark = 'rgba(255, 255, 255, 0.1)';
export const colorIcons = colorGrey60;
export const colorBg = colorGrey10;
export const colorBgDark = colorBlack90;
export const colorOutline = colorGrey30;
export const colorLines = colorGrey40;
export const colorLinesActive = colorGrey60;
export const colorLinesDark = colorGrey70;
export const colorLinesActiveDark = colorGrey40;
export const colorSelection = colorGrey40;
export const colorSelectionDark = colorGrey70;
export const colorBlocks = colorGrey30;
export const colorBlocksDark = colorGrey80;
export const colorOverlay = 'rgba(0, 0, 0, 0.6)';

export const xs = 430;
export const sm = 768;
export const md = 1024;
export const lg = 1200;
export const xl = 1400;
export const xl2 = 1600;
export const xl3 = 1900;
export const desktop = md;
export const frameDesktop = lg;
