export function sidebar(Alpine) {
  Alpine.data('sidebar', ({ id }) => ({
    isOpen: false,
    init() {
      document.querySelectorAll(`[data-open-sidebar="${id}"]`).forEach((item) => {
        item.addEventListener('click', (e) => {
          e.preventDefault();
          this.open();
        });
        if ('isOpen' in item.dataset) this.open();
      });

      this.$watch('isOpen', (isOpen) => {
        if (isOpen) {
          this.$dispatch('sidebar-open', { id });
          document.body.classList.add(`is-${id}-sidebar-open`);
        } else {
          this.$dispatch('sidebar-close', { id });
          document.body.classList.remove(`is-${id}-sidebar-open`);
        }
      });
    },
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
  }));
}
