import TomSelect from 'tom-select';
import { createPopper } from '@popperjs/core';

export function selectComponent(Alpine) {
  Alpine.data('select', ({ isOpen = false, hasSearch = false, placeholder = '' } = {}) => ({
    tom: null,
    init() {
      let settings = {
        controlInput: null,
        copyClassesToDropdown: false,
        wrapperClass: 'select__wrapper',
        controlClass: 'select__control',
        dropdownClass: 'select__dropdown',
        dropdownContentClass: 'select__dropdown-content',
        itemClass: 'select__item',
        optionClass: 'select__option',
        onInitialize: function () {
          this.popper = createPopper(this.control, this.dropdown, {
            modifiers: [
              {
                name: 'computeStyles',
                options: {
                  gpuAcceleration: false, // true by default
                },
              },
            ],
          });

          if (isOpen) this.open();
        },
        onDropdownOpen: function () {
          this.popper.update();
        },
      };

      if (hasSearch) settings.plugins = ['dropdown_input'];
      if (placeholder) settings.placeholder = placeholder;

      this.tom = new TomSelect(this.$root, settings);
    },
    destroy() {
      if (this.tom) this.tom.destroy();
    },
  }));
}

export function transparentSelect(selector = '.transparent-select') {
  document.querySelectorAll(selector).forEach((element) => {
    const label = element.querySelector('.transparent-select__label');
    const select = element.querySelector('select');
    new TomSelect(select, {
      controlInput: null,
      copyClassesToDropdown: false,
      wrapperClass: 'transparent-select__wrapper',
      controlClass: 'transparent-select__control',
      dropdownClass: 'transparent-select__dropdown',
      dropdownContentClass: 'transparent-select__dropdown-content',
      itemClass: 'transparent-select__item',
      optionClass: 'transparent-select__option',
      render: {
        item: function (data, escape) {
          return (
            `<div data-label="${label ? label.textContent : ''}">` + escape(data.text) + '</div>'
          );
        },
      },
      onInitialize: function () {
        this.popper = createPopper(this.control, this.dropdown, {
          placement: 'bottom-start',
          modifiers: [
            {
              name: 'computeStyles',
              options: {
                gpuAcceleration: false, // true by default
              },
            },
          ],
        });
      },
      onDropdownOpen: function () {
        this.popper.update();
      },
    });
  });
}

export function frameSelect(selector = '.frame-select__select', settings = {}) {
  document.querySelectorAll(selector).forEach((element) => {
    new TomSelect(element, {
      controlInput: null,
      copyClassesToDropdown: false,
      wrapperClass: 'frame-select__wrapper',
      controlClass: 'frame-select__control',
      dropdownClass: 'frame-select__dropdown',
      dropdownContentClass: 'frame-select__dropdown-content',
      itemClass: 'frame-select__item',
      optionClass: 'frame-select__option',
      render: {
        option: (data, escape) => '<div>' + escape(data.value) + '</div>',
        item: (data, escape) => '<div class="frame-select__item">' + escape(data.text) + '</div>',
      },
      onInitialize: function () {
        this.popper = createPopper(this.control, this.dropdown, {
          placement: 'bottom-start',
          modifiers: [
            {
              name: 'flip',
              options: {
                padding: 8,
              },
            },
            {
              name: 'computeStyles',
              options: {
                gpuAcceleration: false, // true by default
              },
            },
          ],
        });
      },
      onDropdownOpen: function () {
        this.popper.update();
      },
    });
  });
}
