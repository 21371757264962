/**
 * If url contains file extension.
 *
 * @param {string} url
 * @param skip {array} extensions to skip
 * @returns {boolean}
 */
export function hasExtension(url, skip = ['html']) {
  const parsedUrl = new URL(url);
  const filename = parsedUrl.pathname.split('/').reverse()[0];
  const ext = filename.split('.')[1];
  if (skip.includes(ext)) return false;

  return !!ext;
}
