import Swiper, { EffectFade, Keyboard, Manipulation, Navigation, Pagination } from 'swiper';
import { gsap } from 'gsap';
import { desktop } from '../../common/variables.js';

export function swiperModal(Alpine) {
  Alpine.data('swiperModal', () => ({
    swiper: null,
    init() {
      this.initSwiper();
    },
    initSwiper() {
      if (!('swiper' in this.$refs)) return;

      gsap
        .matchMedia()
        .add(`(max-width: ${desktop - 1}px)`, () => {
          this.swiper = new Swiper(this.$refs.swiper, {
            modules: [Navigation, Pagination, Keyboard, Manipulation],
            spaceBetween: 12,
            slidesPerView: 1,
            keyboard: {
              enabled: true,
            },
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            breakpoints: {
              560: {
                spaceBetween: 64,
              },
            },
          });

          return () => {
            this.swiper.destroy();
            this.swiper = null;
          };
        })
        .add(`(min-width: ${desktop - 1}px)`, () => {
          this.swiper = new Swiper(this.$refs.swiper, {
            modules: [Navigation, Pagination, Keyboard, Manipulation, EffectFade],
            loop: true,
            spaceBetween: 0,
            slidesPerView: 1,
            effect: 'fade',
            fadeEffect: { crossFade: true },
            keyboard: { enabled: true },
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
          });

          return () => {
            this.swiper.destroy();
            this.swiper = null;
          };
        });
    },
    destroy() {
      if (this.swiper) {
        this.swiper.destroy();
      }
    },
  }));
}
