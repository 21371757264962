import { inputmaskLoader } from '../../scripts/lib/inputmaskLoader.js';

export function inputCardExp(Alpine) {
  Alpine.data('inputCardExp', () => ({
    value: '',
    async init() {
      const Inputmask = await inputmaskLoader();

      new Inputmask({
        mask: ['99 / 99'],
        greedy: false,
        placeholder: ' ',
        showMaskOnHover: false,
        showMaskOnFocus: false,
        keepStatic: false,
        clearIncomplete: true,
      }).mask(this.$refs.input);
    },
    destroy() {
      if (this.$refs.input.inputmask) this.$refs.input.inputmask.remove();
    },
  }));
}
