export function inputDate(Alpine) {
  Alpine.data('inputDate', () => ({
    value: '',
    flatpickr: null,

    async init() {
      const { default: flatpickr } = await import('flatpickr');
      this.flatpickr = flatpickr(this.$refs.input, {
        //locale: Russian,
        defaultDate: 'today',
        static: true,
        minDate: 'today',
        dateFormat: 'd.m.Y',
        altInput: true,
        altFormat: 'j M Y',
        monthSelectorType: 'static',
        prevArrow: '<span class="i i--chevron-left-16"></span>',
        nextArrow: '<span class="i i--chevron-right-16"></span>',
      });
    },
    destroy() {
      if (this.flatpickr) this.flatpickr.destroy();
    },
  }));
}
