import ky from 'ky';

export function post(url, params = {}, files = {}) {
  if (Object.keys(files).length > 0) {
    return postMultiPart(url, params, files);
  } else {
    return postURLEncoded(url, params);
  }
}

export function postURLEncoded(url, params = {}) {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (typeof value === 'object') value = JSON.stringify(value);
    searchParams.set(key, value.toString());
  });

  return ky.post(url, { body: searchParams });
}

export function postMultiPart(url, params = {}, files = {}) {
  const formData = new FormData();
  Object.entries(params).forEach(([key, value]) => {
    if (typeof value === 'object') value = JSON.stringify(value);
    formData.append(key, value.toString());
  });

  Object.entries(files).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return ky.post(url, { body: formData });
}
