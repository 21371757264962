import TomSelect from 'tom-select';
import { createPopper } from '@popperjs/core';
import { getUserLocation } from '../../scripts/lib/getUserLocation.js';

export function countrySelect(Alpine) {
  Alpine.data('countrySelect', ({ isOpen = false, hasSearch = false, placeholder = '', useDefVal = false } = {}) => ({
    tom: null,
    init() {
      let settings = {
        controlInput: null,
        copyClassesToDropdown: false,
        wrapperClass: 'select__wrapper',
        controlClass: 'select__control',
        dropdownClass: 'select__dropdown',
        dropdownContentClass: 'select__dropdown-content',
        itemClass: 'select__item',
        optionClass: 'select__option',
        onInitialize: function () {
          this.popper = createPopper(this.control, this.dropdown, {
            modifiers: [
              {
                name: 'computeStyles',
                options: {
                  gpuAcceleration: false, // true by default
                },
              },
            ],
          });
		
		  if(!useDefVal){
          getUserLocation()
            .then((location) => {
              this.setValue(location);
            })
            .catch(() => {
              this.setValue('US');
            });
		  }
          if (isOpen) this.open();
        },
        onDropdownOpen: function () {
          this.popper.update();
        },
      };

      if (hasSearch) settings.plugins = ['dropdown_input'];
      if (placeholder) settings.placeholder = placeholder;

      this.tom = new TomSelect(this.$root, settings);
    },
    destroy() {
      if (this.tom) this.tom.destroy();
    },
  }));
}
