import { isFromScreenWidth, isToScreenWidth } from '../../scripts/lib/screen.js';
import { sm } from '../../common/variables.js';
import { timeout } from '../../scripts/lib/timeout.js';

function onItemClick(e) {
  e.preventDefault();
  const link = e.target;
  if (isFromScreenWidth(sm) && link.href) {
    timeout(300).then(() => (window.location = link.href));
  } else {
    if (!link.classList.contains('is-open')) {
      link.classList.add('is-open');
    } else {
      link.classList.remove('is-open');
    }
  }
}

function initMenu(parentElement) {
  const mainLinks = parentElement.querySelectorAll('.main-menu__button');
  const more = parentElement.querySelector('.main-menu__button--more');
  if (isToScreenWidth(sm)) more.classList.add('is-open');

  mainLinks.forEach((link) => {
    link.addEventListener('click', onItemClick);
  });

  return {
    destroy: () => {
      mainLinks.forEach((link) => {
        link.removeEventListener('click', onItemClick);
      });
    },
  };
}

export function initMainMenus(selector = '.main-menu') {
  const menus = [...document.querySelectorAll(selector)].map((element) => initMenu(element));

  return {
    menus,
    destroy: () => menus.forEach((menu) => menu.destroy()),
  };
}
