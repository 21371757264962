import { gsap } from 'gsap';
import { isLocalStorageAvailable } from '../../scripts/lib/localStorage.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { timeout } from '../../scripts/lib/timeout.js';

export function notification(Alpine) {
  Alpine.data('notification', ({ id }) => ({
    key: `closed_news_notification_${id}`,
    scrollTrigger: null,
    init() {
      const notification = this.$root;
      if (!notification) return;

      if (!isLocalStorageAvailable() || localStorage.getItem(this.key) === '1') {
        this.remove();
      }

      let isStart = true;
      this.scrollTrigger = ScrollTrigger.create({
        trigger: 'main',
        start: 'top top',
        onToggle: ({ progress, direction, isActive }) => {
          isStart = false;
        },
        onEnter: () =>
          gsap.to(notification, {
            autoAlpha: 0,
            y: -30,
            ease: 'customEaseInOut',
            duration: 0.5,
          }),
        onLeaveBack: () =>
          gsap.fromTo(
            notification,
            { autoAlpha: 0, y: -30 },
            { autoAlpha: 1, y: 0, ease: 'customEaseInOut', duration: 0.5, immediateRender: false }
          ),
      });

      window.addEventListener('custom-preloading-finished', async () => {
        await timeout(6000);
        if (isStart) gsap.fromTo(notification, { autoAlpha: 0 }, { autoAlpha: 1 });
      });
    },
    remove() {
      if (this.scrollTrigger) this.scrollTrigger.kill();
      if (this.$root) this.$root.remove();
      localStorage.setItem(this.key, '1');
    },
    close() {
      gsap.to(notification, { autoAlpha: 0, onComplete: () => this.remove() });
    },
  }));
}
