import { getUserLocation } from '../../scripts/lib/getUserLocation.js';
import { timeout } from '../../scripts/lib/timeout.js';
import { inputmaskLoader } from '../../scripts/lib/inputmaskLoader.js';

export function inputPhone(Alpine) {
  Alpine.data('inputPhone', () => ({
    value: '',
    iti: null,
    async init() {
      // postpone intl-tel-input loading, since all phone fields are inside sidebars
      await timeout(2000);

      // preload input mask library
      await inputmaskLoader(0);

      const { default: intlTelInput } = await import('intl-tel-input');
      this.iti = intlTelInput(this.$refs.input, {
        nationalMode: false,
        showSelectedDialCode: true,
        showFlags: true,
        utilsScript: '/assets/js/intl-tel-input-utils.js', // 200kb of pure evil
        formatOnDisplay: false,
        formatAsYouType: false,
        initialCountry: 'auto',
        useFullscreenPopup: false,
        autoPlaceholder: 'aggressive',
        i18n: {
          searchPlaceholder: 'Search for country',
        },
        geoIpLookup: (callback) => {
          getUserLocation()
            .then((location) => {
              if (location) callback(location);
            })
            .catch(() => {
              callback('US');
            });
        },
      });

      this.iti.promise.then(() => {
        if (this.value) {
          this.iti.setNumber(this.value);
        }

        this.updateMask();
        this.$refs.input.addEventListener('countrychange', () => this.updateMask());
      });
    },
    async updateMask() {
      const Inputmask = await inputmaskLoader();
      const placeholder = this.$refs.input.getAttribute('placeholder');
      if (!placeholder) return;
      const mask = placeholder.replace(/[0-9+]/gi, '9');
      if (!mask) return;

      if (!this.$refs.input.inputmask) {
        new Inputmask({
          mask,
          greedy: false,
          placeholder: ' ',
          showMaskOnHover: false,
          showMaskOnFocus: false,
          keepStatic: false,
        }).mask(this.$refs.input);
      } else {
        this.$refs.input.inputmask.option({ mask });
      }
    },
    onInput() {
      this.value = this.iti.getNumber();
    },
    destroy() {
      if (this.$refs.input.inputmask) this.$refs.input.inputmask.remove();
      if (this.iti) this.iti.destroy();
    },
  }));
}
