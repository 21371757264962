import Pristine from 'pristinejs/dist/pristine';
import { post } from '../../scripts/lib/requests.js';
import { pristineInputConfig } from '../input/input.js';

export function stayTune(Alpine) {
  Alpine.data('stayTune', ({ type, action }) => ({
    email: '',
    policy: false,
    isSubmitting: false,
    step: 'form', // form, success, error
    pristine: null,
    init() {
      this.pristine = new Pristine(
        this.$refs.form,
        {
          classTo: 'form-item',
          errorClass: 'has-error',
          successClass: 'is-ok',
          errorTextParent: 'form-item',
          errorTextTag: 'small',
          errorTextClass: 'error',
        },
        false
      );
    },
    async onSubmit(e) {
      if (!this.pristine.validate()) return;
      this.isSubmitting = true;

      try {
        await post(action, { type, email: this.email });
        this.isSubmitting = false;
        this.step = 'success';
      } catch (e) {
        this.step = 'error';
      }
    },
  }));
}
