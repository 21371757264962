export function getFormInputs(form) {
  return Object.values(form).reduce((obj, field) => {
    if (!field.name) return obj;

    if (field.type === 'checkbox' || field.type === 'radio') {
      if (field.checked) {
        if (!obj[field.name]) obj[field.name] = [];
        obj[field.name].push(field.value);
      }
      return obj;
    }

    obj[field.name] = field.value;

    return obj;
  }, {});
}

export function getFormFiles(form) {
  return Object.values(form).reduce((obj, field) => {
    if (field.name && field.files) {
      obj[field.name] = field.files[0];
    }
    return obj;
  }, {});
}
