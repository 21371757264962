import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { acceptAllCookies } from '../../scripts/lib/api/cookies.js';
import { isLocalStorageAvailable } from '../../scripts/lib/localStorage.js';
import { timeout } from '../../scripts/lib/timeout.js';

export function cookiesDialog(Alpine) {
  Alpine.data('cookiesDialog', ({ timeout = 10000 } = {}) => ({
    isOpen: false,
    trigger: null,

    init() {
      if (!isLocalStorageAvailable()) return;

      const trigger = ScrollTrigger.create({
        trigger: document.body,
        start: 'top top-=50%',
        once: true,
        onEnter: () => this.open(),
      });

      const timer = setTimeout(() => this.open(), timeout);

      this.$watch('isOpen', (isOpen) => {
        clearTimeout(timer);
        trigger.kill();

        if (isOpen) {
          document.body.classList.add(`is-cookies-dialog-open`);
        } else {
          document.body.classList.remove(`is-cookies-dialog-open`);
        }
      });
    },
    async acceptAll() {
      acceptAllCookies();
      this.close();
    },
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
  }));
}
