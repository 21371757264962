import { timeout } from '../../scripts/lib/timeout.js';

export async function initVideoJs() {
  const videos = document.querySelectorAll('.video-js');
  if (!videos.length) return;

  await timeout(1000);

  const { default: videojs } = await import('video.js');
  await import('videojs-youtube');
  videos.forEach((videoElement) => {
    const args = { fluid: true };
    const poster = videoElement.getAttribute('poster');
    if (poster) args['poster'] = poster;

    const ytSrc = videoElement.getAttribute('data-youtube-src');
    if (ytSrc) {
      args['techOrder'] = ['youtube'];
      args['sources'] = [{ type: 'video/youtube', src: ytSrc }];
    }

    videojs(videoElement, args);
  });
}
