import { timeout } from './timeout.js';

let Inputmask;

export async function inputmaskLoader(delay = 2000) {
  if (Inputmask) return Inputmask;

  // inputmask is a heavy script, wait for everything else to load before using it
  if (delay) await timeout(delay);
  const { default: LoadedInputmask } = await import('inputmask');
  Inputmask = LoadedInputmask;

  return Inputmask;
}
