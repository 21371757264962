import { inputmaskLoader } from '../../scripts/lib/inputmaskLoader.js';
import { getCreditCardType } from '../../scripts/lib/creditCardType.js';

export function inputCard(Alpine) {
  Alpine.data('inputCard', () => ({
    value: '',
    get cardTypeClass() {
      const type = getCreditCardType(this.value);
      return type ? `is-${type}` : '';
    },
    async init() {
      const Inputmask = await inputmaskLoader();

      new Inputmask({
        mask: ['9999 9999 9999 9999'],
        greedy: false,
        placeholder: ' ',
        showMaskOnHover: false,
        showMaskOnFocus: false,
        keepStatic: false,
        clearIncomplete: true,
      }).mask(this.$refs.input);
    },
    destroy() {
      if (this.$refs.input.inputmask) this.$refs.input.inputmask.remove();
    },
  }));
}
