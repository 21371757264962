import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import focus from '@alpinejs/focus';
import { log } from '../scripts/lib/directives/log.js';
import { stayTune } from '../components/stay-tune/stayTune.js';
import { inputGroup } from '../components/input/input-group.js';
import { inputCard } from '../components/input/inputCard.js';
import { inputPhone } from '../components/input/inputPhone.js';
import { inputCardPin } from '../components/input/inputCardPin.js';
import { inputCardExp } from '../components/input/inputCardExp.js';
import { fileInput } from '../components/file-input/fileInput.js';
import { frameSelect, selectComponent } from '../components/select/select.js';
import { sidebar } from '../components/sidebar/sidebar.js';
import { contactFormSidebar } from '../components/sidebar/contactFormSidebar.js';
import { waitingListSidebar } from '../components/sidebar/waitingListSidebar.js';
import { cookiesSidebar } from '../components/sidebar/cookiesSidebar.js';
import { cookiesDialog } from '../components/cookies-dialog/cookiesDialog.js';
import { modal } from '../components/modal/modal.js';
import { galleryModal } from '../components/gallery-modal/gallery-modal.js';
import { fullscreenModalLoader } from '../components/fullscreen-modal/fullscreen-modal.js';
import { modalLoader } from '../components/modal/modalLoader.js';
import { notification } from '../components/notification/notification.js';
import { isResizing } from '../scripts/lib/isResizing.js';
import { pageTransition } from '../scripts/lib/pageTransition.js';
import { tooltip } from '../components/tooltip/tooltip.js';
import { frame } from '../components/frame/frame.js';
import { initMainMenus } from '../components/main-menu/mainMenu.js';
import { inputDate } from '../components/input/inputDate.js';
import { swiperModal } from '../components/swiper-modal/swiperModal.js';
import { countrySelect } from '../components/select/countrySelect.js';
import { initVideoJs } from '../components/videojs/videojs.js';

export function initGSAP() {
  gsap.registerPlugin(CustomEase);
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(ScrollToPlugin);
  CustomEase.create('customEaseInOut', 'M0,0,C0.47,0,0.64,1,1,1');
  gsap.defaults({ ease: 'power2.in' });
}

export function initCommonJS() {
  initGSAP();
  frame();
  initMainMenus();
  isResizing();
  pageTransition();
  frameSelect();
  tooltip();
  initVideoJs().then();
}

export function initAlpinePlugins(Alpine) {
  Alpine.plugin(log);
  Alpine.plugin(focus);
  Alpine.plugin(stayTune);
  Alpine.plugin(inputGroup);
  Alpine.plugin(inputCard);
  Alpine.plugin(inputPhone);
  Alpine.plugin(inputCardPin);
  Alpine.plugin(inputCardExp);
  Alpine.plugin(inputDate);
  Alpine.plugin(fileInput);
  Alpine.plugin(selectComponent);
  Alpine.plugin(countrySelect);
  Alpine.plugin(sidebar);
  Alpine.plugin(contactFormSidebar);
  Alpine.plugin(waitingListSidebar);
  Alpine.plugin(cookiesSidebar);
  Alpine.plugin(cookiesDialog);
  Alpine.plugin(modal);
  Alpine.plugin(swiperModal);
  Alpine.plugin(galleryModal);
  Alpine.plugin(fullscreenModalLoader);
  Alpine.plugin(modalLoader);
  Alpine.plugin(notification);
}
