import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { isFromScreenWidth, isToScreenWidth } from '../../scripts/lib/screen.js';
import { frameDesktop, lg, sm } from '../../common/variables.js';
import { timeout } from '../../scripts/lib/timeout.js';
import tippy from 'tippy.js';

export function initFullFrame(frameEl) {}

export function frame() {
  const frameEl = document.querySelector('.frame');
  if (!frameEl) return;

  const mobileMenuButton = frameEl.querySelector('.frame__mobile-menu-button');
  const tabletMenuButton = frameEl.querySelector('.frame__tablet-menu-button');
  const more = frameEl.querySelector('.main-menu__button--more');
  const country = frameEl.querySelector('.frame__country');
  const sideButtons = document.querySelectorAll('.small-button.side-button');

  const isDark = () => frameEl.classList.contains('frame--is-dark');
  const goDark = () => {
    console.log('frame goes dark');
    frameEl.classList.add('frame--is-dark');
    country.dataset.sidebarIsMobileDark = '';
    localStorage.setItem('frame-is-from-theme', 'dark');

    if (sideButtons.length > 0) {
      sideButtons.forEach((btn) => {
        btn.classList.add('btn-dark');
      });
    }
  };
  const goLight = () => {
    frameEl.classList.remove('frame--is-dark');
    delete country.dataset.sidebarIsMobileDark;
    localStorage.setItem('frame-is-from-theme', 'light');

    if (sideButtons.length > 0) {
      sideButtons.forEach((btn) => {
        btn.classList.remove('btn-dark');
      });
    }
  };

  if (frameEl.classList.contains('frame--is-full')) {
    const isOpen = () => frameEl.classList.contains('frame--is-open');
    const open = () => {
      frameEl.classList.add('frame--disabled-transitions');
      timeout(300).then(() => frameEl.classList.remove('frame--disabled-transitions'));

      frameEl.classList.add('frame--is-open');
      mobileMenuButton.classList.add('is-active');
      mobileMenuButton.innerHTML = 'Close';
      localStorage.setItem('frame-is-from-open', '1');
    };
    const close = () => {
      frameEl.classList.remove('frame--is-open');
      mobileMenuButton.classList.remove('is-active');
      mobileMenuButton.innerHTML = 'Navigate';

      if (isToScreenWidth(sm)) {
        frameEl
          .querySelectorAll('.main-menu__link')
          .forEach((link) => link.classList.remove('is-open'));
      }
    };

    const toggle = () => {
      if (!isOpen()) {
        open();
      } else {
        close();
      }
    };

    mobileMenuButton.addEventListener('click', toggle);
    tabletMenuButton.addEventListener('click', toggle);
    more.addEventListener('click', () => {
      if (isFromScreenWidth(sm)) toggle();
    });

    gsap.matchMedia().add(`(min-width: ${sm}px)`, () => {
      if (isOpen()) close();
    });

    const minimize = () => {
      frameEl.classList.add('frame--is-minimized');
      localStorage.setItem('frame-is-from-minimized', '1');
    };
    const maximize = () => {
      frameEl.classList.remove('frame--is-minimized');
      localStorage.removeItem('frame-is-from-minimized');
    };

    const mobileHide = () => frameEl.classList.add('frame--is-mobile-hidden');
    const mobileShow = () => frameEl.classList.remove('frame--is-mobile-hidden');

    let lastScrollPos = 0;
    const update = () => {
      // minimize on desktop
      const scrollPos = window.scrollY;
      if (scrollPos > 20) {
        minimize();
      } else {
        maximize();
      }

      // hide on mobile
      const isScrollingDown = scrollPos > lastScrollPos;
      if (isScrollingDown) {
        mobileHide();
      } else {
        mobileShow();
      }
      lastScrollPos = scrollPos;
    };
    update();
    document.addEventListener('scroll', update);

    gsap.matchMedia().add(`(max-width: ${frameDesktop - 1}px)`, () => {
      if (isDark()) goLight();
    });

    gsap.matchMedia().add(`(min-width: ${sm}px)`, () => {
      const bikeComponents = document.querySelector('.bike-components');
      if (bikeComponents) {
        ScrollTrigger.create({
          trigger: bikeComponents,
          start: 'top+=18% top',
          end: 'bottom top+=40%',
          onEnter: goDark,
          onEnterBack: goDark,
          onLeave: goLight,
          onLeaveBack: goLight,
          // markers: true,
        });
      }
    });

    const footerUpdate = () => {
      const rolloutHeight = isFromScreenWidth(lg) ? 500 : 615;

      const windowBottomPos = window.scrollY + window.innerHeight;
      const offsetFromBottom = document.documentElement.scrollHeight - windowBottomPos;
      let height =
        offsetFromBottom > rolloutHeight ? 0 : Math.round(rolloutHeight - offsetFromBottom);
      if (height >= 56) {
        frameEl.classList.add('frame--is-at-footer');
        frameEl.style.setProperty('--height', `${height}px`);
      } else {
        frameEl.style.setProperty('--height', null);
        frameEl.classList.remove('frame--is-at-footer');
      }
    };

    footerUpdate();
    document.addEventListener('scroll', footerUpdate);

    const newsFooter = document.querySelector('.news-footer');
    if (newsFooter) {
      gsap.matchMedia().add(`(min-width: ${sm}px)`, () => {
        ScrollTrigger.create({
          trigger: '.news-footer',
          start: 'top bottom-=60%',
          onEnter: goDark,
          onLeaveBack: goLight,
        });
      });
    }

    const signature = frameEl.querySelector('.frame__signature');
    const tooltip = frameEl.querySelector('.frame__member-tooltip');
    if (signature && tooltip) {
      tippy(signature, {
        content: tooltip,
        zIndex: 1000,
        theme: 'small',
        duration: [600, 250],
        interactive: true,
        appendTo: () => document.body,
        onCreate: () => (tooltip.hidden = false),
      });
    }
  }

  const darkBlocks = document.querySelectorAll('[data-frame-go-dark]');
  if (darkBlocks.length > 0) {
    gsap.matchMedia().add(`(min-width: ${sm}px)`, () => {
      darkBlocks.forEach((block) => {
        ScrollTrigger.create({
          trigger: block,
          start: 'top bottom-=60%',
          end: 'bottom top+=40%',
          onEnter: goDark,
          onEnterBack: goDark,
          onLeave: goLight,
          onLeaveBack: goLight,
        });
      });
    });
  }

  const lightBlocks = document.querySelectorAll('[data-frame-go-light]');
  if (lightBlocks.length > 0) {
    gsap.matchMedia().add(`(min-width: ${sm}px)`, () => {
      lightBlocks.forEach((block) => {
        ScrollTrigger.create({
          trigger: block,
          start: 'top bottom-=60%',
          end: 'bottom top+=40%',
          onEnter: goLight,
          onEnterBack: goLight,
          onLeave: goDark,
          onLeaveBack: goDark,
        });
      });
    });
  }
}
