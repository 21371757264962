import { timeout } from '../../scripts/lib/timeout.js';
import { rejectAllCookies, sendAcceptedCookies } from '../../scripts/lib/api/cookies.js';

export function cookiesSidebar(Alpine) {
  Alpine.data('cookiesSidebar', ({ type, action }) => ({
    isSubmitting: false,
    selectedCookies: ['functional', 'performance'],
    get isAllSelected() {
      return (
        this.selectedCookies.includes('functional') && this.selectedCookies.includes('performance')
      );
    },
    init() {},
    resetPanelScroll() {
      timeout(300).then(() => {
        if (this.$refs.panel) this.$refs.panel.scrollTop = 0;
      });
    },
    clear() {
      this.resetPanelScroll();
    },
    async onSubmit() {
      this.$dispatch('accept-cookies');
      sendAcceptedCookies(['necessary', ...this.selectedCookies]);
      this.resetPanelScroll();
    },
    async onReject() {
      this.$dispatch('accept-cookies');
      this.isSubmitting = true;
      rejectAllCookies();
      this.resetPanelScroll();
    },
  }));
}
