export function inputGroup(Alpine) {
  Alpine.data('inputGroup', ({ maxLength = 0 } = {}) => ({
    value: '',
    group: {},
    input: {},
    maxLength,
    get length() {
      return this.value.length;
    },
    init() {
      if (maxLength) {
        this.$watch('value', (newValue) => {
          if (newValue.length > maxLength) {
            this.value = newValue.slice(0, maxLength);
          }
        });
      }

      if (!this.$refs.input) return;
      const input = this.$refs.input;

      if (input.tagName.toLowerCase() === 'textarea') {
        input.style.height = (input.scrollHeight ? input.scrollHeight : 31) + 'px';
        this.$watch('value', () => {
          input.style.height = 'auto';
          input.style.height = (input.scrollHeight ? input.scrollHeight : 31) + 'px';
        });
      }
    },
  }));
}
