import { setCookie } from '../cookies.js';

export const cookieTypes = ['necessary', 'functional', 'performance'];

export function sendAcceptedCookies(cookies) {
  setCookie('cookiesParams', JSON.stringify(cookies));
}

export function acceptAllCookies() {
  setCookie('cookiesParams', JSON.stringify(cookieTypes));
}

export function rejectAllCookies() {
  setCookie('cookiesParams', JSON.stringify([]));
}
