import Pristine from 'pristinejs/dist/pristine';
import { pristineInputConfig } from '../input/input.js';
import { timeout } from '../../scripts/lib/timeout.js';
import { post } from '../../scripts/lib/requests.js';
import { getFormFiles, getFormInputs } from '../../scripts/lib/form.js';

export function contactFormSidebar(Alpine) {
  Alpine.data('contactFormSidebar', ({ type, action }) => ({
    step: 'form', // form, success, error
    successResponse: '',
    isSubmitting: false,
    pristine: null,
    init() {
      this.$nextTick(() => this.initValidator());
    },
    resetPanelScroll() {
      timeout(300).then(() => {
        if (this.$refs.panel) this.$refs.panel.scrollTop = 0;
      });
    },
    clear() {
      this.pristine.reset();
      if (this.$refs.form) this.$refs.form.reset();
      if (this.step === 'form') return;

      this.step = 'form';
      this.resetPanelScroll();
    },
    initValidator() {
      this.pristine = new Pristine(this.$refs.form, pristineInputConfig, false);
    },
    resetValidator() {
      this.pristine.reset();
      this.pristine.destroy();
      this.initValidator();
    },
    async onSubmit() {
      if (!this.pristine.validate()) {
        const errors = this.pristine.getErrors();
        errors[0].input.scrollIntoView({ behavior: 'smooth' });
        return;
      }

      const inputValues = getFormInputs(this.$refs.form);
      const fileValues = getFormFiles(this.$refs.form);
      this.isSubmitting = true;

      try {
        const response = await post(action, { type, ...inputValues }, fileValues);
        this.successResponse = await response.text();
        this.isSubmitting = false;
        this.resetPanelScroll();
        this.step = 'success';
      } catch (e) {
        if (e.response && e.response.status === 400) {
          const html = await e.response.text();
          this.$refs.formSection.innerHTML = html;
          this.$nextTick(() => {
            this.resetValidator();
            this.resetPanelScroll();
          });
        } else {
          this.step = 'error';
        }

        this.isSubmitting = false;
      }
    },
  }));
}
