import ky from 'ky';
import { timeout } from './timeout.js';

let isFetching = false;
let location = null;

/**
 * Returns user location's ISO 3166-1 alpha-2 country code.
 *
 * @param defaultLocation
 * @returns {Promise<string|null>}
 */
export async function getUserLocation(defaultLocation = 'US') {
  while (isFetching) {
    await timeout(100);
  }

  if (location) {
    return location;
  }

  try {
    const code = localStorage.getItem('user_location_country_code');
    if (code) {
      location = code;
      return location;
    }
  } catch (e) {}

  try {
    isFetching = true;
    const data = await ky.get('https://ipapi.co/json').json();
    const code = data.country_code;
    if (code) {
      location = code;
      isFetching = false;

      try {
        localStorage.setItem('user_location_country_code', location);
      } catch (e) {}

      return location;
    }
  } catch (e) {}
  isFetching = false;

  return defaultLocation;
}
