import ky from 'ky';
import { on } from 'delegated-events';

export function modalLoader(Alpine) {
  Alpine.data('modalLoader', () => ({
    isOpen: false,
    url: null,
    controls: null,
    init() {
      setTimeout(() => {
        this.initOpenTriggers();
      }, 1000);
    },
    async open(url, tab = null) {
      if (this.url && url === this.url) {
        this.isOpen = true;
        return;
      }

      this.url = url;
      this.$root.innerHTML = await ky.get(this.url).text();

      this.isOpen = true;
    },
    close() {
      if (this.controls) this.controls.reset();
      this.isOpen = false;
    },
    initOpenTriggers() {
      const self = this;
      on('click', '[data-modal-url]', function (e) {
        e.preventDefault();
        self.open(this.dataset.modalUrl);
      });
    },
  }));
}
