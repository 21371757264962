import Swiper, { Keyboard, Manipulation, Navigation, Pagination } from 'swiper';

export function modal(Alpine) {
  Alpine.data('modal', ({ id, isOpen = false }) => ({
    isOpen,
    swiper: null,
    init() {
      this.initOpenTriggers();
      this.initSwiper();
    },
    open() {
      this.isOpen = true;
    },
    getSwiperSlide(url) {
      return `
       <div class="swiper-slide swiper-modal__slide">
          <button class="swiper-modal__slide-close" aria-label="Close this dialog window"
                  @click="close()">
            <span class='i i--close-32'></span>
          </button>
          <picture class="swiper-modal__slide-picture">
            <img src="${url}" alt=""/>
          </picture>
        </div>`;
    },
    openWithGallery(items, itemIndex = 0) {
      this.isOpen = true;

      if (!items || !this.swiper) return;

      this.swiper.removeAllSlides();

      items.forEach((item) => {
        this.swiper.appendSlide(this.getSwiperSlide(item.href));
      });

      this.swiper.slideTo(itemIndex);
    },
    close() {
      this.isOpen = false;
    },
    initOpenTriggers() {
      document.querySelectorAll(`[data-open-gallery-modal]`).forEach((galleryGroup) => {
        const items = galleryGroup.querySelectorAll('a[href]');
        items.forEach((item, idx) => {
          item.addEventListener('click', (e) => {
            e.preventDefault();
            this.openWithGallery(items, idx);
          });
        });
      });

      document.querySelectorAll(`[data-open-modal="${id}"]`).forEach((item) => {
        item.addEventListener('click', (e) => {
          e.preventDefault();
          this.open();
        });
      });
    },
    initSwiper() {
      if (!('swiper' in this.$refs)) return;
      console.log(this.$root);

      const isFullwidth = this.$root.classList.contains('swiper-modal--is-mobile-fullwidth');

      this.swiper = new Swiper(this.$refs.swiper, {
        modules: [Navigation, Pagination, Keyboard, Manipulation],
        spaceBetween: isFullwidth ? 0 : 12,
        slidesPerView: 1,
        keyboard: {
          enabled: true,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          880: {
            spaceBetween: 0,
          },
        },
        // on: {
        //   init: function (swiper) {
        //     console.log('swiper initialized');
        //     swiper.removeAllSlides();
        //   },
        // },
      });
    },
  }));
}
