import Swiper, { Navigation, Pagination, EffectFade, Thumbs, Keyboard } from 'swiper';
import { gsap } from 'gsap';

export function galleryModal(Alpine) {
  Alpine.data('galleryModal', () => ({
    isOpen: false,
    swiper: null,
    thumbnailSwiper: null,

    init() {
      const mm = gsap.matchMedia();
      mm.add('(max-width: 599px)', () => {
        this.swiper = new Swiper(this.$refs.swiper, {
          modules: [Navigation, Pagination, Keyboard],
          spaceBetween: 12,
          slidesPerView: 1,
          keyboard: {
            enabled: true,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });

        return () => {
          this.swiper.destroy();
          this.swiper = null;
        };
      });

      mm.add('(min-width: 600px)', () => {
        this.thumbnailSwiper = new Swiper(this.$refs.thumbnailSwiper, {
          slidesPerView: 'auto',
          spaceBetween: 12,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          breakpoints: {},
        });

        this.swiper = new Swiper(this.$refs.swiper, {
          modules: [Navigation, Pagination, EffectFade, Thumbs, Keyboard],
          loop: true,
          effect: 'fade',
          spaceBetween: 0,
          keyboard: {
            enabled: true,
          },
          thumbs: {
            swiper: this.thumbnailSwiper,
          },
          fadeEffect: {
            crossFade: true,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });

        return () => {
          this.swiper.destroy();
          this.thumbnailSwiper.destroy();
          this.thumbnailSwiper = null;
          this.swiper = null;
        };
      });
    },
    close() {
      this.isOpen = false;
    },
    destroy() {
      if (this.swiper) this.swiper.destroy();
      if (this.thumbnailSwiper) this.thumbnailSwiper.destroy();
    },
  }));
}
