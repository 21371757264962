import { timeout } from './timeout.js';
import { setCookie } from './cookies.js';
import { hasExtension } from './url.js';
import { isToScreenWidth } from './screen.js';
import { frameDesktop } from '../../common/variables.js';
import { isValidURL } from './isValidURL.js';

function startTransition(frameType, pageColor) {
  const body = document.body;
  const frame = document.querySelector('.frame');

  let currentFrameType;
  if (!frame) currentFrameType = 'hidden';
  else if (frame.classList.contains('frame--is-simple')) currentFrameType = 'simple';
  else currentFrameType = 'full';

  const currentPageColor = body.classList.contains('is-dark') ? 'dark' : 'light';

  if (frame) {
    frame.classList.remove('frame--is-minimized');
    frame.classList.remove('frame--is-open');
  }

  if (currentFrameType !== frameType) {
    if (frame) frame.classList.add(`frame--is-to-${frameType}`);
    setCookie('frame-transition', `from-${currentFrameType}`, { 'max-age': 10 });
  }

  if (currentPageColor !== pageColor) {
    if (pageColor === 'dark') {
      if (frame) frame.classList.remove('frame--is-light');
      body.classList.remove('is-light');
      if (frame) frame.classList.add('frame--is-dark');
      body.classList.add('is-dark');
    } else if (pageColor === 'light') {
      if (frame) frame.classList.remove('frame--is-dark');
      body.classList.remove('is-dark');
      if (frame) frame.classList.add('frame--is-light');
      body.classList.add('is-light');
    }
  }
}

export function pageTransition(containerSelector = '.page__main') {
  const container = document.querySelector(containerSelector);
  if (!container) return;

  const animatedElements = document.querySelectorAll('.has-initial-animation');
  animatedElements.forEach((element) => {
    element.addEventListener('animationend', () =>
      element.classList.remove('has-initial-animation')
    );
  });

  const links = document.querySelectorAll("a[href]:not([href^='#'])");

  links.forEach((link) => {
    if (!link.href) return;
    if (!isValidURL(link)) return;
    if (hasExtension(link.href)) return;

    link.addEventListener('click', (e) => {
      // skip external links
      if (e.currentTarget.hostname !== location.hostname) return;

      // skip main menu links, we process them in mainMenu()
      if (link.classList.contains('main-menu__link') && isToScreenWidth(frameDesktop)) return;

      e.preventDefault();

      container.classList.add('is-fading');
      startTransition(link.dataset.frameTransition, link.dataset.pageTransition);
      timeout(550).then(() => (window.location = link.href));
    });
  });

  // FIXME: a hack to bring a page to a viewable state if it was loaded by pressing back.
  const interval = setInterval(() => {
    if (String(window.performance.getEntriesByType('navigation')?.[0]?.type) === 'back_forward') {
      clearInterval(interval);
      window.location.reload(); // hideous: gods of the web are already preparing a cauldron for me
      return;
    }

    if (window.performance.navigation.type === 2) {
      clearInterval(interval);
      window.location.reload();
    }
  }, 100);
}
