export function isResizing(containerSelector = 'body') {
  const container = document.querySelector(containerSelector);

  let timer;
  window.addEventListener('resize', () => {
    container.classList.add('is-resizing');
    clearTimeout(timer);

    timer = setTimeout(() => container.classList.remove('is-resizing'), 200);
  });
}
