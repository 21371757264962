export function fileInput(Alpine) {
  Alpine.data('fileInput', () => ({
    initialLabel: '',
    file: null,
    get hasFile() {
      return this.file;
    },
    get label() {
      return this.file ? this.file.name : this.initialLabel;
    },
    init() {
      this.initialLabel = this.$refs.text.textContent;
    },
    onChange(e) {
      this.file = e.target.files[0];
    },
  }));
}
